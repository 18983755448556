<template>
    <b-modal
        size='lg'
        :title="title"
        @hidden='hideModal'
        ref='OperationBaseCostModal'
        id='operation-base-cost-modal'
    >
        <errors-bag-list></errors-bag-list>

        <b-table
            stipred
            :items="items"
            :fields="fields"
            head-variant="dark"
            :no-local-sorting="true"
        >
            <template #cell(date)="data">
                <template v-if='data.item.current'>{{ data.item.date }}</template>
                <template v-else-if='operation && data.item.new'>
                    <date-time-picker
                        locale='ru'
                        input-size='sm'
                        label='Выберите дату'

                        :min-date='minDate'
                        :disabled-dates='disabledDates'

                        :overlay='true'
                        :no-label='true'
                        :no-header='true'
                        :only-date='true'
                        :no-button='true'
                        :auto-close='true'
                        :no-clear-button='true'

                        format='YYYY-MM-DD'
                        formatted='YYYY-MM-DD'

                        v-model='newItem.date'
                    ></date-time-picker>
                </template>
                <template v-else>
                    <date-time-picker
                        locale='ru'
                        input-size='sm'
                        label='Выберите дату'

                        :min-date='minDate'
                        :disabled-dates='disabledDates'

                        :overlay='true'
                        :no-label='true'
                        :no-header='true'
                        :only-date='true'
                        :no-button='true'
                        :auto-close='true'
                        :no-clear-button='true'

                        format='YYYY-MM-DD'
                        formatted='YYYY-MM-DD'

                        v-model='data.item.date'
                    ></date-time-picker>
                </template>
            </template>

            <template #cell(cost)="data">
                <template v-if='data.item.current'>{{ data.item.cost }}</template>
                <template v-else-if='operation && data.item.new'>
                    <b-form-input v-model='newItem.cost' type='number' step='0.01' min='0.01' max='99.00'></b-form-input>
                </template>
                <template v-else>
                    <b-form-input v-model='data.item.cost' type='number' step='0.01' min='0.01' max='99.00'></b-form-input>
                </template>
            </template>

            <template #cell(actions)="data">
                <template v-if='data.item.current'>Активная</template>
                <template v-else-if='operation && data.item.new'>
                    <b-button variant='success' @click='createOperationBaseCoast'>Добавить</b-button>
                </template>
                <template v-else>
                    <b-button variant='outline-primary' @click='saveOperationBaseCost( data.item )'>
                        <i class='fas fa-save'></i>
                    </b-button>
                    <b-button variant="outline-danger" @click='deleteOperationBaseCost( data.item )'>
                        <i class="fas fa-times"></i>
                    </b-button>
                </template>
            </template>
        </b-table>

        <template #modal-footer>
            <div class="w-100">
                <div class='pull-right'>
                    <b-button variant="danger" size="lg" @click="hideModal">Закрыть</b-button>
                </div>
            </div>
        </template>
    </b-modal>
</template>

<script>
    import moment from 'moment';
    import map from 'lodash/map';
    import forEach from 'lodash/forEach';
    import cloneDeep from 'lodash/cloneDeep';

    import DateTimePicker from 'vue-ctk-date-time-picker';

    import ErrorsBagList from '../_common/ErrorsBagList';

    import ErrorsBag from '../../utils/errorsBag';
    import { SALARY_ENDPOINT } from '../../utils/endpoints';

    const DATE_FORMAT = 'YYYY-MM-DD';

    const NEW_ITEM_PATTERN = {
        cost: 0,
        date: null
    };

    export default {
        name: "OperationBaseCostModal",
        components: { ErrorsBagList, DateTimePicker },
        props: {
            operation: { type: Object, default: () => null }
        },
        computed: {
            title() {
                let title = 'Базовые ставки';

                if ( this.operation ) {
                    title += ' по операции "' + this.operation.name + '"';
                }

                return title;
            },
            operationName() {
                if ( !this.operation ) {
                    return '';
                }

                return this.operation.name;
            },
            minDate() {
                return moment().add( 1, 'days' ).format( DATE_FORMAT )
            },
            disabledDates() {
                if ( !( this.items.length > 0 ) ) {
                    return [];
                }

                return map( this.items, item => moment( item.date ).format( DATE_FORMAT ) );
            }
        },
        data() {
            return {
                ErrorsBag,

                current: null,
                newItem: cloneDeep( NEW_ITEM_PATTERN ),

                items: [],
                fields: [
                    { key: 'operation', label: 'Операция' },
                    { key: 'date', label: 'Дата' },
                    { key: 'cost', label: 'Базовая ставка, руб.' },
                    { key: 'actions', label: 'Действия' },
                ]
            };
        },
        methods: {
            hideModal() {
                this.$refs.OperationBaseCostModal.hide();
                this.clearModal();
            },
            initModal() {
                this.fetchCurrentOperationBaseCost()
                    .then( () => {
                        this.fetchActiveOperationBaseCosts();
                        this.$refs.OperationBaseCostModal.show();
                    } );
            },
            clearModal() {
                ErrorsBag.discard();

                this.items = [];
                this.current = null;
                this.newItem = cloneDeep( NEW_ITEM_PATTERN );
            },

            prepareItems( items ) {
                let preparedItems = [];

                if ( this.operation ) {
                    preparedItems.push( { operation: this.operationName, new: true, current: false } );
                }

                if ( this.current ) {
                    let preparedCurrentItem = {
                        id: this.current.id,
                        operation: this.current.operation.name,
                        cost: parseFloat( this.current.cost ).toFixed( 2 ),
                        date: moment( this.current.date ).format( DATE_FORMAT ),

                        new: false,
                        current: true
                    };

                    preparedItems.push( preparedCurrentItem );
                }

                forEach( items, item => {
                    let preparedItem = {
                        id: item.id,
                        operation: item.operation.name,
                        cost: parseFloat( item.cost ).toFixed( 2 ),
                        date: moment( item.date ).format( DATE_FORMAT ),

                        new: false,
                        current: false
                    };

                    preparedItems.push( preparedItem );
                } );

                return preparedItems;
            },

            createOperationBaseCoast() {
                ErrorsBag.discard();

                let params = {
                    cost: parseFloat( this.newItem.cost ).toFixed( 2 ),
                    operation: { id: this.operation ? this.operation.id : null },
                    date: this.newItem.date ? moment( this.newItem.date ).format( DATE_FORMAT ) : null
                };

                this.$http
                    .post( SALARY_ENDPOINT + '/operation-costs', params )
                    .then( () => this.initModal(), response => ErrorsBag.fill( response.body ) );
            },
            saveOperationBaseCost( record ) {
                ErrorsBag.discard();

                let params = {
                    cost: parseFloat( record.cost ).toFixed( 2 ),
                    operation: { id: this.operation ? this.operation.id : null },
                    date: record.date ? moment( record.date ).format( DATE_FORMAT ) : null
                };

                this.$http
                    .put( SALARY_ENDPOINT + '/operation-costs/' + record.id, params )
                    .then( () => this.fetchActiveOperationBaseCosts(), response => ErrorsBag.fill( response.body ) );
            },
            deleteOperationBaseCost( record ) {
                ErrorsBag.discard();

                if ( confirm( 'Вы уверены, что хотите удалить эту базовую ставку?' ) ) {
                    this.$http
                        .delete( SALARY_ENDPOINT + '/operation-costs/' + record.id )
                        .then(
                            () => this.fetchActiveOperationBaseCosts(),
                            response => ErrorsBag.fill( response.body )
                        );
                }
            },

            fetchCurrentOperationBaseCost() {
                this.clearModal();

                let params = {
                    operation: { id: this.operation ? this.operation.id : null }
                };

                return this.$http
                    .get( SALARY_ENDPOINT + '/operation-costs/current', { params } )
                    .then( response => this.current = response.data.data, response => ErrorsBag.fill( response.body ) );
            },
            fetchActiveOperationBaseCosts() {
                ErrorsBag.discard();

                let params = {
                    with: [ 'operation' ],
                    orders: { date: 'asc' },
                    filters: {
                        following: 1,
                        operation: { id: this.operation ? this.operation.id : null }
                    },
                };

                this.$http
                    .get( SALARY_ENDPOINT + '/operation-costs', { params } )
                    .then(
                        response => this.items = this.prepareItems( response.data.data.data ),
                        response => ErrorsBag.fill( response.body )
                    );
            }
        }
    }
</script>

<style lang='sass' scoped>

</style>
