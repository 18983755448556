<template>
    <b-modal
        size='lg'
        class='text-center'
        ref='SetDifficultiesModal'
        id='set-difficulties-modal'
        title='Установка сложности'
        @hidden='hideModal( false )'
    >
        <errors-bag-list></errors-bag-list>

        <b-row class='row-margin'>
            <b-col md='12'><h4>Спецификации</h4></b-col>
            <b-col md='12' v-for='specification in this.specifications' :key='specification.id' class='text-left'>
                <span v-html='getSpecificationInfo( specification )'></span>
            </b-col>
        </b-row>

        <b-row>
            <b-col md='12'>
                <b-input-group prepend='Сложность:' :append='calculatedPrice()'>
                    <b-form-input v-model='difficulty' type='number' min='0.01' step='0.01' max='10.00'></b-form-input>
                </b-input-group>
            </b-col>
        </b-row>

        <template #modal-footer>
            <div class="w-100">
                <div class='pull-right'>
                    <b-button variant="danger" size="lg" @click="hideModal( false )">Закрыть</b-button>
                    <b-button variant='success' size='lg' @click='setDifficulties'>Установить</b-button>
                </div>
            </div>
        </template>
    </b-modal>
</template>

<script>
    import moment from 'moment';
    import forEach from 'lodash/forEach';
    import cloneDeep from 'lodash/cloneDeep';

    import ErrorsBagList from '../_common/ErrorsBagList';

    import ErrorsBag from '../../utils/errorsBag';
    import { SALARY_ENDPOINT, SPECIFICATIONS_ENDPOINT } from '../../utils/endpoints';

    export default {
        name: "SetDifficultiesModal",
        components: { ErrorsBagList },
        props: {
            value: { type: Array, default: () => [] },
            operation: { type: Object, default: () => null }
        },
        watch: {
            value: {
                deep: true,
                handler() {
                    if ( this.value.length > 0 ) {
                        this.specifications = cloneDeep( this.value );
                    }
                }
            },
            specifications: {
                deep: true,
                handler() {
                    if ( JSON.stringify( this.value ) !== JSON.stringify( this.specifications ) ) {
                        this.$emit( 'input', this.specifications );
                    }
                }
            }
        },
        data() {
            return {
                moment,
                ErrorsBag,

                difficulty: null,
                specifications: [],
                currentOperationBaseCost: null
            };
        },
        methods: {
            hideModal( established = true ) {
                this.$refs.SetDifficultiesModal.hide();
                this.clearModal( established );
            },
            initModal() {
                this.fetchCurrentOperationBaseCost();
                this.$refs.SetDifficultiesModal.show();
            },
            clearModal( established = true ) {
                ErrorsBag.discard();

                if ( established ) {
                    this.specifications = [];
                    this.$emit( 'established' );
                }

                this.difficulty = null;
                this.currentOperationBaseCost = null;
            },

            calculatedPrice( difficulty = null ) {
                let prepend = '<b class="text-danger">';
                let append = ' руб.</b>';

                if ( !this.currentOperationBaseCost || ( !this.difficulty && difficulty === null ) ) {
                    return prepend + 0 + append;
                }

                let price = this.currentOperationBaseCost.cost * ( difficulty > 0 ? difficulty : this.difficulty );

                return prepend + parseFloat( price.toString() ).toFixed( 2 ) + append;
            },
            getSpecificationInfo( specification ) {
                return '<b>' + specification.nomenclature.name + '</b> от <b> ' +
                    moment( specification.date ).format( 'DD.MM.YYYY' ) +
                    '</b> (сложность: <b>' + ( specification.difficulty ? specification.difficulty : 0 )  +
                    '</b>; стоимость: <b>' + this.calculatedPrice( specification.difficulty ) + '</b>)';
            },

            setDifficulties() {
                ErrorsBag.discard();

                if (
                    confirm( 'Вы действительно хотите установить указанное значение сложности для этих спецификаций?' )
                ) {
                    let params = { difficulties: [] };
                    forEach( this.specifications, specification => {
                        params.difficulties.push( {
                            specification: { id: specification.id, difficulty: this.difficulty }
                        } );
                    } );

                    this.$http
                        .put( SPECIFICATIONS_ENDPOINT + '/set-difficulties', params )
                        .then( () => this.hideModal(), response => ErrorsBag.fill( response.body ) );
                }
            },

            fetchCurrentOperationBaseCost() {
                this.currentOperationBaseCost = null;

                let params = { operation: { id: this.operation.id } };

                this.$http
                    .get( SALARY_ENDPOINT + '/operation-costs/current', { params } )
                    .then(
                        response => this.currentOperationBaseCost = response.data.data,
                        response => ErrorsBag.fill( response.body )
                    );
            }
        }
    }
</script>

<style lang='sass' scoped>
    .row-margin
        margin-bottom: 20px
</style>
