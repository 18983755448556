<template>
    <div style='overflow:hidden;'>
        <errors-bag-list></errors-bag-list>

        <b-row class='sticky-top fixed-panel' align-v='center'>
            <b-col md='4'>
                <div class='pull-left'>
                    <b-button-group v-if='specificationDifficulties.length > 0'>
                        <b-button variant='danger' @click='clearSpecificationDifficulties'>Отменить</b-button>
                        <b-button variant='primary' @click='openSetDifficultiesModal'>Установить сложность</b-button>
                    </b-button-group>
                </div>
            </b-col>
            <b-col md='4'>
                <b-pagination
                    :limit='9'
                    align='center'
                    style='margin:0'
                    :total-rows='rows'
                    v-model='currentPage'
                    :per-page='itemsPerPage'
                    v-if='items.length > this.itemsPerPage'
                ></b-pagination>
            </b-col>
            <b-col md='4'>
                <div class="pull-right">
                    <b-button
                        v-if='operation'
                        variant='warning'
                        :operation='operation'
                        @click='openOperationBaseCostModal()'
                    >
                        <span v-html='currentOperationBaseCostTitle'></span>
                    </b-button>
                    <b-button variant='primary' @click='applyFilters'>Обновить</b-button>
                </div>
            </b-col>
        </b-row>

        <b-card no-body class='mb-1 text-center'>
            <b-card-header header-tag="header" class="p-2">
                <b-row align-v='center'>
                    <b-col md='4'>
                        <template v-if='this.nomenclatureId'>
                            <strong>{{ nomenclature.name }}</strong>
                        </template>
                        <template v-else>
                            <nomenclature-input v-model="filters.nomenclature" :clear-button='true'></nomenclature-input>
                        </template>
                    </b-col>
                    <b-col md='3'>
                        <b-form-select v-model="filters.difficulty_defined" size='lg'>
                            <template #first>
                                <option :value="null">Все спецификации</option>
                            </template>

                            <option :value="true">Сложность установлена</option>
                            <option :value="false">Сложность не установлена</option>
                        </b-form-select>
                    </b-col>
                    <b-col md='5'>
                        <div class='pull-right' v-if='filtersExists'>
                            <b-button variant='outline-dark' @click="resetFilters">Сбросить фильтр</b-button>
                        </div>
                    </b-col>
                </b-row>
            </b-card-header>

            <b-form-checkbox-group
                id='specification_difficulties'
                name='specification_difficulties'
                v-model='specificationDifficulties'
            >
                <table class='specification-table' v-if='paginatedItems.length > 0'>
                    <tbody>
                        <tr v-for='nomenclature in paginatedItems'>
                            <td>
                                <div
                                    class='nomenclature-name bg-info'
                                    :class='{ "bg-danger": difficultyNotDefine( nomenclature ) }'
                                >
                                    <h5><b>{{ nomenclature.name }}</b></h5>
                                </div>

                                <template v-for='specification in nomenclature.specifications'>
                                    <b-row class='specification-info' align-v='center'>
                                        <b-col md='1'>
                                            <b-form-checkbox :value='specification'></b-form-checkbox>
                                        </b-col>
                                        <b-col md='1'>
                                            <b>Активна с:</b><br/>{{ formatDate( specification.date ) }}
                                        </b-col>
                                        <b-col class='text-left' md='8'>
                                            <b>Описание:</b> {{ specification.description }}
                                        </b-col>
                                        <b-col md='2'>
                                            <div class='specification-difficulty'>
                                                <b-input-group>
                                                    <b-form-input
                                                        min='0.01'
                                                        step='0.01'
                                                        max='10.00'
                                                        type='number'
                                                        :state="true"
                                                        v-model='specification.difficulty'
                                                    ></b-form-input>

                                                    <template #append>
                                                        <b-button
                                                            variant='outline-success'
                                                            @click='setDifficulty( specification )'
                                                        >
                                                            <i class='fas fa-check'></i>
                                                        </b-button>
                                                    </template>
                                                </b-input-group>
                                            </div>
                                        </b-col>
                                    </b-row>
                                    <b-row class='specification-items text-left'>
                                        <b-col md='1'></b-col>
                                        <b-col md='9'>
                                            <b-badge
                                                :key='item.id'
                                                class='specification-item'
                                                v-for='item in specification.items'
                                                :variant='specification.difficulty > 0 ? "primary" : "danger"'
                                            >
                                                {{ item.nomenclature.name }}&nbsp;
                                                <b-badge variant='light'>
                                                    {{ item.count }} {{ item.nomenclature.measure_unit.name }}
                                                </b-badge>
                                            </b-badge>
                                        </b-col>
                                        <b-col class='text-danger text-right' md='2'>
                                            <b class='specification-price'>
                                                Стоимость: {{ priceCalculate( specification ) }} руб.
                                            </b>
                                        </b-col>
                                    </b-row>
                                </template>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </b-form-checkbox-group>
        </b-card>

        <operation-base-cost-modal ref='OperationBaseCost' :operation='operation'></operation-base-cost-modal>

        <set-difficulties-modal
            ref='SetDifficulties'
            :operation='operation'
            v-model='specificationDifficulties'
            @established='fetchSpecifications()'
        ></set-difficulties-modal>
    </div>
</template>

<script>
    import moment from 'moment';
    import find from 'lodash/find';

    import ErrorsBagList from '../_common/ErrorsBagList';
    import NomenclatureInput from '../_common/NomenclatureInput';

    import ErrorsBag from '../../utils/errorsBag';
    import lsfMixin from '../../utils/localStorageFilterMixin';
    import {
        SALARY_ENDPOINT,
        OPERATIONS_ENDPOINT,
        NOMENCLATURES_ENDPOINT,
        SPECIFICATIONS_ENDPOINT
    } from '../../utils/endpoints';

    import SetDifficultiesModal from './SetDifficultiesModal';
    import OperationBaseCostModal from './OperationBaseCostModal';

    const PACKING_OPERATION_ID = 5;

    const DATE_FORMAT = 'YYYY-MM-DD';

    const FILTERS_PATTERN = {
        nomenclature: null,
        difficulty_defined: null
    };

    export default {
        name: "SpecificationsDashboard",
        components: { ErrorsBagList, NomenclatureInput, OperationBaseCostModal, SetDifficultiesModal },
        props: {
            nomenclature_id: { type: String, default: null }
        },
        mixins: [ lsfMixin ],
        data() {
            return {
                ErrorsBag,

                currentPage: 1,
                itemsPerPage: 10,
                paginatedItems: [],

                items: [],
                operation: null,
                specificationDifficulties: [],
                currentOperationBaseCost: null,

                nomenclature: null,
                nomenclatureId: this.nomenclature_id ? parseInt( this.nomenclature_id ) : null
            };
        },
        computed: {
            rows() {
                return this.items.length;
            },
            currentOperationBaseCostTitle() {
                if ( !this.currentOperationBaseCost ) {
                    return 'Базовая ставка: <b>нет активной</b>';
                }

                return 'Базовая ставка: <b>' +
                    parseFloat( this.currentOperationBaseCost.cost ).toFixed( 2 ) + ' руб. </b> начиная с <b>' +
                    moment( this.currentOperationBaseCost.date ).format( 'DD.MM.YYYY' ) + '</b>';
            }
        },
        watch: {
            filters: {
                deep: true,
                handler( value ) {
                    this.watchFilters( value );
                }
            },
            currentPage() {
                this.clearSpecificationDifficulties();
                this.calculatePagination();
            }
        },
        methods: {
            formatDate( date ) {
                return moment( date ).format( DATE_FORMAT );
            },
            priceCalculate( specification ) {
                if ( !this.currentOperationBaseCost || !specification.difficulty ) {
                    return 0;
                }

                let price = this.currentOperationBaseCost.cost * specification.difficulty;

                return parseFloat( price.toString() ).toFixed( 2 );
            },
            difficultyNotDefine( nomenclature ) {
                return find( nomenclature.specifications, specification => specification.difficulty <= 0 );
            },
            clearSpecificationDifficulties() {
                this.specificationDifficulties = [];
            },
            setDefaultPagination() {
                this.currentPage = 1;
                this.itemsPerPage = 10;
                this.paginatedItems = [];
            },
            calculatePagination() {
                let skip = ( this.currentPage - 1 ) * this.itemsPerPage;

                this.paginatedItems = this.items.slice( skip, skip + this.itemsPerPage );
            },

            openSetDifficultiesModal() {
                this.$refs.SetDifficulties.initModal();
            },
            openOperationBaseCostModal() {
                this.$refs.OperationBaseCost.initModal();
            },

            setDifficulty( specification ) {
                ErrorsBag.discard();

                let params = {
                    difficulties: [
                        { specification: { id: specification.id, difficulty: specification.difficulty } }
                    ]
                };

                this.$http
                    .put( SPECIFICATIONS_ENDPOINT + '/set-difficulties', params )
                    .then( () => this.fetchSpecifications(), response => ErrorsBag.fill( response.body ) );
            },

            applyFilters() {
                ErrorsBag.discard();

                this.setDefaultPagination();
                this.clearSpecificationDifficulties();

                this.fetchOperation();
                this.fetchCurrentOperationBaseCost();
                this.fetchSpecifications();
            },
            fetchOperation() {
                this.operation = null;

                this.$http
                    .get( OPERATIONS_ENDPOINT + '/' + PACKING_OPERATION_ID )
                    .then( response => this.operation = response.data.data );
            },
            fetchNomenclature() {
                if ( this.nomenclatureId ) {
                    this.$http
                        .get( NOMENCLATURES_ENDPOINT + '/' + this.nomenclatureId )
                        .then( response => this.nomenclature = response.data.data );
                }
            },
            fetchSpecifications() {
                this.items = [];

                let filters = this.getFilters;

                let difficultyDefined = null;
                switch ( filters.difficulty_defined ) {
                    case true:
                        difficultyDefined = 1;
                        break;
                    case false:
                        difficultyDefined = 0;
                        break;
                }

                let nomenclatureId = filters.nomenclature ? filters.nomenclature.id : null;
                if ( this.nomenclatureId ) {
                    nomenclatureId = this.nomenclatureId;
                }

                let params = {
                    filters: {
                        nomenclature: { id: nomenclatureId },
                        difficulty_defined: difficultyDefined,
                        operation: { id: PACKING_OPERATION_ID },
                    }
                };

                this.$http
                    .get( SPECIFICATIONS_ENDPOINT + '/dashboard', { params } )
                    .then(
                        response => {
                            this.items = response.data.data;
                            this.calculatePagination();
                        },
                        response => ErrorsBag.fill( response.body )
                    );
            },
            fetchCurrentOperationBaseCost() {
                this.currentOperationBaseCost = null;

                let params = { operation: { id: PACKING_OPERATION_ID } };

                this.$http
                    .get( SALARY_ENDPOINT + '/operation-costs/current', { params } )
                    .then(
                        response => this.currentOperationBaseCost = response.data.data,
                        response => ErrorsBag.fill( response.body )
                    );
            }
        },
        created() {
            this.fetchNomenclature();
            this.initFilters( this, FILTERS_PATTERN );
        }
    }
</script>

<style lang='sass' scoped>
    .fixed-panel
        padding: 9px 10px
        background-color: #fff

    .nomenclature-name
        color: #fff
        padding: 10px

    .specification-item
        font-size: 1.1em
        padding: 8px 10px
        border-radius: 8px
        margin: 10px 0 0 10px

    .specification-badge
        font-size: .9em

    .specification-price,
    .specification-difficulty
        margin-right: 10px

    .specification-info,
    .specification-items
        padding: 5px 0
        border-top: 1px solid #e4e5e6

    .specification-table
        border: 0
        width: 100%
        border-spacing: 0
        border-collapse: collapse

        & tr
            border: 0
            & td
                padding: 0
                border-bottom: 1px solid #e4e5e6

        & tr:last-child
            & td:last-child
                border: 0
</style>
